// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import "./controllers"
import "./channels"
import "./bootstrap_initializers"
import "./config"
import "@fortawesome/fontawesome-free/js/all"

require("@rails/activestorage").start()
require("local-time").start()
