import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["matrix"]

  reverse (event) {
    if (event.target.control) {
      // noop if checkbox checked
      if (event.target.control.checked) {
        return
      }
    }

    const matrix = this.matrixTarget

    for (var i = 1; i < matrix.childNodes.length; i++) {
      matrix.insertBefore(matrix.childNodes[i], matrix.firstChild)
    }
  }
}
