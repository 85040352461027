import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hours", "minutes", "rate", "result"]
  // We appear to be calculating a 35 hour week by the following formula:
  // anual salary / 365.161705073682 * 7

  static BasicMinuteRate = 57.77238095238095
  static SundayMinuteRate = 69.3322916667

  connect() {
    const modal = document.getElementById("pay-calculator-modal")
    const hours = document.getElementById("pay-calculator-modal-hours")
    const mins = document.getElementById("pay-calculator-modal-minutes")

    modal.addEventListener("shown.bs.modal", () => {
      hours.focus()
      hours.select()
    })

    mins.addEventListener("focus", () => mins.select())
  }

  calculate() {
    let hours = parseInt(this.hoursTarget.value) || 0
    let minutes = parseInt(this.minutesTarget.value) || 0
    let rate = this.rateTargets.find((r) => r.checked).value
    let totalMinutes = hours * 60 + minutes
    let result
    if (rate === "basic") {
      result = this.calculateBasic(totalMinutes)
    } else {
      result = this.calculateSunday(totalMinutes)
    }
    this.resultTarget.textContent = `£${(result / 100).toFixed(2)}`
  }

  calculateBasic(totalMinutes) {
    return totalMinutes * this.constructor.BasicMinuteRate
  }

  calculateSunday(totalMinutes) {
    return totalMinutes * this.constructor.SundayMinuteRate
  }
}
