import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  suggest(_event) {
    let stations = document.getElementsByName("suggestion[query][]")
    let data = {}
    stations.forEach(function (station, key) {
      data[key] = { name: station.value, stopping: station.checked }
    })
    let up = document.getElementById("suggestion_direction_of_travel_up")
    let direction
    if (up.checked) {
      direction = "up"
    } else {
      direction = "down"
    }

    this.stimulate("SuggestionReflex#suggest", data, direction)
  }
}
