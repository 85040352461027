import { Controller } from "@hotwired/stimulus"
import L from "leaflet"

export default class extends Controller {

  static targets = ["container", "lat", "lng"]
  static values = { currentLocation: Array }
  connect() {
    this.map = L.map(this.containerTarget)

    this.initializeView()

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution: "&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a>",
    }).addTo(this.map)

    this.map.on("moveend", this.updateLocation.bind(this))
  }

  initializeView() {
    if (this.isNewLocation()) {
      this.map.locate({ setView: true, maximumAge: 180000 }).on("locationerror", () => {
        const DEFAULT_LOCATION = [51.494, -0.14]
        const DEFAULT_ZOOM = 13

        this.map.setView( DEFAULT_LOCATION, DEFAULT_ZOOM)
      }).on("locationfound", () => {
        this.map.setZoom(17)
      })

    }
    else {
      let location = this.currentLocationValue
      this.map.setView([location[0], location[1]], 17)
    }
  }

  isNewLocation() {
    return this.currentLocationValue[0] === null
  }

  updateLocation() {
    const center = this.map.getCenter()
    this.latTarget.value = center.lat
    this.lngTarget.value = center.lng
  }

  disconnect() {
    this.map.remove()
  }
}
