import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="geo-sort"
export default class extends Controller {

  // intercepts navigation and adds current location to the url as query params
  request (event) {
    event.preventDefault()
    if (!navigator.geolocation) {
      Turbo.visit(event.target.href)
      return
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.addCurrentLocation(position, event)
      },
      (_error) => {
        Turbo.visit(event.target.href)
      },
    )
  }

  addCurrentLocation (position, event) {
    const url = new URL(event.target.href)
    url.searchParams.append("lat", position.coords.latitude)
    url.searchParams.append("lng", position.coords.longitude)
    Turbo.visit(url.toString())
  }
}
